<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-6">
                <strong>Otorisasi Auth</strong>
                <a-button
                  class="btn btn-outline-primary ml-3"
                  v-if="changed"
                  @click="saveChange"
                  >Save Changes</a-button
                >
                <a-button
                  class="btn btn-outline-danger ml-2"
                  v-if="changed"
                  @click="resetAll"
                  >Cancel</a-button
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <!-- :columns="columns" -->
                <a-table
                  style="
                    margin-left: -26px;
                    margin-right: -26px;
                    margin-top: -21px;
                  "
                  :dataSource="datatable"
                  :defaultExpandAllRows="true"
                  :expandIconAsCell="true"
                  :expandIconColumnIndex="0"
                  rowKey="id"
                  size="small"
                  :pagination="{
                    defaultPageSize: 50,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} items`,
                    pageSizeOptions: ['100', '150', '200'],
                  }"
                >
                  <a-table-column key="description" data-index="description">
                    <span slot="title" style="">Description</span>
                    <template slot="customRender" slot-scope="text, record">
                      <strong v-if="record.users.length !== 0">{{ text }}</strong>
                      <label v-else>{{ text }}</label>
                    </template>
                  </a-table-column>
                  <!-- <template> -->
                    <a-table
                      slot="expandedRowRender"
                      slot-scope="record, index"
                      style="margin: 0;"
                      v-if="record.users.length !== 0"
                      :bordered="true"
                      :data-source="record.users"
                      size="small"
                      :pagination="{
                        hideOnSinglePage: true,
                        defaultPageSize: 10,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total) => `Total ${total} items`,
                        pageSizeOptions: ['10', '20', '30'],
                      }"
                    >
                      <a-table-column key="first_name" data-index="first_name">
                        <span slot="title" style="">
                          <a-button type="primary" size="small" @click="addNullData(record, index)">Tambah</a-button>
                        </span>
                        <span slot="customRender" slot-scope="text, record">
                          {{ text }} <a-divider type="vertical" /> <a-icon type="delete" @click="showDeleteConfirm(record.user_permission_id)" class="text-danger" style="cursor: pointer;"/>
                        </span>
                      </a-table-column>
                      <a-table-column
                        key="user_id"
                        data-index="user_id"
                        :width="150"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          >User</span
                        >
                        <a-select
                          style="width: 200px;"
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          v-model="record[column.dataIndex]"
                          @change="
                            (e) => selectedChangeUser(e, record, index, column)
                          "
                        >
                          <a-select-option v-for="(data, index) in datauser" :key="index" :value="data.id">{{ data.chat_username }}</a-select-option>
                        </a-select>
                      </a-table-column>
                      <a-table-column
                        data-index="batas_atas"
                        :width="150"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          >Batas Atas</span
                        >
                        <cleave
                          :options="{ numeral: true }"
                          class="ant-input"
                          :raw="true"
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          :value="record[column.dataIndex]"
                          @input="
                            (e) => inputChange(e, record, index, column)
                          "
                        />
                      </a-table-column>
                      <a-table-column
                        data-index="batas_bawah"
                        :width="150"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          >Batas Bawah</span
                        >
                        <cleave
                          :options="{ numeral: true }"
                          class="ant-input"
                          :raw="true"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          :value="record[column.dataIndex]"
                          @input="
                            (e) => inputChange(e, record, index, column)
                          "
                        />
                      </a-table-column>
                      <a-table-column
                        key="auth"
                        data-index="auth"
                        :width="70"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          >Auth</span
                        >
                        <a-select
                          style="width: 100px;"
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          v-model="record[column.dataIndex]"
                          @change="
                            (e) => selectedChange(e, record, index, column)
                          "
                        >
                          <a-select-option :value="null">Not Set</a-select-option>
                          <a-select-option :value="1">Verify</a-select-option>
                          <a-select-option :value="2">Approve</a-select-option>
                        </a-select>
                      </a-table-column>
                    </a-table>
                  <!-- </template> -->
                  <!-- <template
                    slot="expandedRowRender"
                    slot-scope="record"
                    style="margin: 0;"
                  >
                    <a-table
                      :columns="childColumns"
                      :dataSource="record.list_permission"
                      size="small"
                      :pagination="{
                        hideOnSinglePage: true,
                        defaultPageSize: 10,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total) => `Total ${total} items`,
                        pageSizeOptions: ['10', '20', '30'],
                      }"
                    >
                    </a-table>
                  </template> -->
                  <a-checkbox
                    :key="componentKey"
                    slot="checkbox"
                    slot-scope="text, record, index, column"
                    :checked="text == 1"
                    @change="(e) => selectedCheckbox(e, record, index, column)"
                  />
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  data() {
    return {
      name: 'Permissions',
      datauser: [],
      changed: false,
      changedData: [],
      role: '',
      rolename: 'Loading...',
      expandedRowKeys: [],
      columns: [
        {
          title: 'Description',
          dataIndex: 'description',
          scopedSlots: { customRender: 'description' },
        },
        {
          title: 'Delete',
          dataIndex: 'delete',
          scopedSlots: { customRender: 'checkbox' },
          align: 'center',
          width: 70,
        },
      ],
      table: 'permission',
      form: this.$form.createForm(this, { name: 'Form ' + this.table }),
      datatable: [],
      datarole: [],
      componentKey: 0,
    }
  },
  created() {
    this.getRole()
  },
  methods: {
    checkExist() {},
    expandedRowsChange(expanded, record) {
      console.log('expanded', expanded)
      if (expanded) {}
    },
    addNullData(record, index) {
      // console.log('record', record)
      // console.log('index', index)
      var fd = {
        id: record.id,
        user_permission_id: this.$uuid.v4(),
        user_id: null,
        first_name: null,
        batas_bawah: null,
        batas_atas: null,
        auth: null,
        auth_name: 'Not Set',
        createable: true,
      }
      // console.log('fd', fd)
      record.users.splice(0, 0, fd)
      this.changedData.push(fd)
    },
    selectedChangeUser(e, record, index, column) {
      // console.log('e', e)
      // console.log('record', record)
      // console.log('index', index)
      // console.log('column', column)
      if (column.dataIndex === 'user_id') {
        var valuetarget = this.datauser.findIndex(x => x.id === e)
        record.first_name = this.datauser[valuetarget].first_name
      }
      this.executeData(e, record, column)
    },
    selectedChange(e, record, index, column) {
      // console.log('e', e)
      // console.log('record', record)
      // console.log('index', index)
      // console.log('column', column)
      this.executeData(e, record, column)
    },
    inputChange(e, record, index, column) {
      var ne = e !== '' ? parseFloat(e) : null
      // console.log('index', index)
      // console.log('column', column)
      // console.log(record[column.dataIndex], ne)
      if (record[column.dataIndex] !== ne) {
        this.executeData(ne, record, column)
      } else {
        var indexTarget = this.changedData.findIndex(x => x.user_permission_id === record.user_permission_id && x.createable !== undefined)
        // console.log('indexTarget', indexTarget)
        if (indexTarget >= 0) {
          this.changedData.splice(indexTarget, 1)
        }
      }
      // console.log('this.changedData', this.changedData)
    },
    executeData(e, record, column) {
      console.log('e', e)
      console.log('record', record.first_name)
      // console.log('record.user_permission_id', record.user_permission_id)
      var indexTarget = this.changedData.findIndex(x => x.user_permission_id === record.user_permission_id)
      if (indexTarget < 0) {
        // console.log('indexTarget', this.changedData[indexTarget])
        this.changedData.push(record)
      } else {
        var ndata = this.changedData[indexTarget]
        ndata[column.dataIndex] = e
      }
      if (this.changedData.length !== 0) {
        this.changed = true
      }
      // console.log('this.changedData', this.changedData)
    },
    selectedCheckbox(e, record, index, column) {
      record[column.dataIndex] = e.target.checked ? 1 : 0
      // console.log(column, record[column.dataIndex])
      var dataHasChildren = (datas) => { // Infinity if there's children
        datas.children.forEach((el) => {
          el.access = e.target.checked ? 1 : 0
          el.create = e.target.checked ? 1 : 0
          el.read = e.target.checked ? 1 : 0
          el.update = e.target.checked ? 1 : 0
          el.delete = e.target.checked ? 1 : 0
          if (el.children.length !== 0) { // Checking if children has children
            dataHasChildren(el) // Run self is there's children
          }
        })
      }
      if (column.dataIndex === 'all') {
        // var exist = this.checkAll(record)
        if (e.target.checked) {
          record.access = 1
          record.create = 1
          record.read = 1
          record.update = 1
          record.delete = 1
          if (record.children.length !== 0) {
            dataHasChildren(record) // First trigger magic if there's children selected(true)
          }
          var chdatat = this.$g.clone(record)
          // delete chdatat.children
          var tgdatat = this.changedData.findIndex(el => el.id_Permission === record.id_Permission)
          if (tgdatat >= 0) {
            this.changedData[tgdatat] = chdatat
          } else {
            this.changedData.push(chdatat)
          }
        } else {
          record.access = 0
          record.create = 0
          record.read = 0
          record.update = 0
          record.delete = 0
          if (record.children.length !== 0) {
            dataHasChildren(record) // First trigger magic if there's children selected(false)
          }
          var chdataf = this.$g.clone(record)
          // delete chdataf.children
          var tgdataf = this.changedData.findIndex(x => x.id_Permission === record.id_Permission)
          if (tgdataf >= 0) {
            this.changedData[tgdataf] = chdataf
          } else {
            this.changedData.push(chdataf)
          }
        }
      }
      if (record.children.length !== 0) {
        record.children.forEach((x) => {
          x[column.dataIndex] = e.target.checked
        })
      }
      // console.log('this.changedData', this.changedData)
      this.componentKey += 1
      this.changed = true
    },
    async getRole() {
      var response = await lou.customUrlGet('telegram_user', false, false)
      this.datauser = response.data
      this.getData()
    },
    checkAll(record) {
      var adakosong = false
      if (record.access === 0) {
        adakosong = true
      }
      if (record.create === 0) {
        adakosong = true
      }
      if (record.read === 0) {
        adakosong = true
      }
      if (record.update === 0) {
        adakosong = true
      }
      if (record.delete === 0) {
        adakosong = true
      }
      return adakosong
    },
    async saveChange() {
      // console.log('this.datatable', this.datatable)
      // console.log('this.changedData', this.changedData)
      var yangdisimpan = []
      this.changedData.forEach((x) => {
        // if (x.children !== undefined && x.children.length !== 0) {
        // x.children.forEach((el) => {
        // yangdisimpan.push(el)
        // })
        // delete x.children
        // }
        // console.log('x.createable', x.createable)
        if (x.createable === true) {
          x.user_permission_id = null
          delete x.createable
        }
        yangdisimpan.push(x)
      })
      var fd = yangdisimpan
      console.log('fd', fd)
      // console.log('fd', JSON.stringify(fd))
      var response = await lou.customUrlPost('telegram_auth/many', fd)
      // console.log('response', response)
      if (!response.isError) {
        // this.datatable = response.data
        this.getData()
        // this.datatable = response.data
        // console.log('res.data', res.data)
        // this.datatable.all = 0
        this.changed = false
      }
    },
    async getData() {
      this.componentKey += 1
      var res = await lou.customUrlGet('telegram_auth', false, false)
      this.datatable = res.data
      this.backupdatatable = this.$g.clone(this.datatable)
      // console.log('res.data', res.data)
      // this.datatable.all = 0
    },
    resetAll() {
      this.changed = false
      this.changedData = []
      this.datatable = this.$g.clone(this.backupdatatable)
    },
    selectAll(column, from, index) {
      if (from === 'children') {
        var targetsubmenu = this.datatable[index].children.filter((x) => x[column] === 0)
        if (targetsubmenu.length === 0) {
          this.datatable[index].children.forEach((element) => {
            element[column] = targetsubmenu[column] === 0 ? 1 : 0
          })
        } else {
          this.datatable[index].children.forEach((element) => {
            element[column] = targetsubmenu[column] === 1 ? 0 : 1
          })
        }
      } else {
        if (column === 'all') {
          this.datatable.forEach((x) => {
            x.create = x.create === 0 ? 1 : 0
            x.read = x.read === 0 ? 1 : 0
            x.update = x.update === 0 ? 1 : 0
            x.delete = x.delete === 0 ? 1 : 0
          })
        } else {
          var target = this.datatable.filter((x) => x[column] === 0)
          if (target.length === 0) {
            this.datatable.forEach((element) => {
              element[column] = target[column] === 0 ? 1 : 0
            })
          } else {
            this.datatable.forEach((element) => {
              element[column] = target[column] === 1 ? 0 : 1
            })
          }
        }
      }
      this.changed = true
    },
    selectAllRow(column) {},
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.deleteMaster('telegram_auth', deldata, true, false)
          this.data = response.data
          this.getData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    getnamerole() {
      var target = this.datarole.filter((x) => x.id === this.role)[0]
      this.rolename = target.name
      this.getData()
    },
    clickcheckboxall() {
      this.$refs.checkboxall.click()
    },
  },
}
</script>
